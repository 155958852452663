import { differenceInSeconds } from "date-fns";

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match("rgba")) {
    let triplet = hex.slice(5).split(",").slice(0, -1).join(",");
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");

    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    c = "0x" + c.join("");

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
};

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substring(2, tempId.length - 1);
  return uid;
}

export function formatCurrency(amount) {
  const formattedNumber = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: "COP",
    currencyDisplay: "code", // Esto evita que se añada el símbolo de moneda, solo se usa el código de la moneda
  }).format(amount);

  // Reemplaza el código de moneda (USD) con el signo $
  return formattedNumber.replace("COP", "$");
}

export const formatDate = (date) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString(undefined, options);
};

export const formatDateUniversal = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() es 0-indexado
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getLast7Days = () => {
  const today = new Date(); // Fecha actual
  const dates = [];
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() - i); // Restar días desde la fecha actual
    dates.unshift({
      date: formatDateUniversal(currentDate), // Formatear fecha
      dayName: getDayName(currentDate), // Obtener el nombre del día
    });
  }

  return dates;
};

const getDayName = (date) => {
  const dayNames = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  return dayNames[date.getDay()];
};

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]); // Retorna el Base64
    reader.onerror = (error) => reject(error); // Manejo de errores
    reader.readAsDataURL(file); // Convierte el archivo a Base64
  });
};

export const getFileNameAndExtension = (filePath) => {
  const parts = filePath.split("/").pop().split(".");
  const extension = "." + parts.pop(); // Último elemento
  const name = parts.join("."); // Resto del nombre
  return { name, extension };
};
