import { httpTypes } from "app/utils/constant";
import { request } from "app/utils/http-client";

const URL_GET_CATEGORIES = "/category/categoriesbycompany";
const URL_SAVE_CATEGORY = "/category/create";
const URL_EDIT_CATEGORY = "/category/update";

export const getCategoryList = (id) => {
  const params = {
    requestId: id,
  };
  return request(URL_GET_CATEGORIES, httpTypes.GET,null, params);
};

export const saveCategoryData = (body) => {
  return request(URL_SAVE_CATEGORY, httpTypes.POST, body);
};

export const editCategoryData = (body) => {
  return request(URL_EDIT_CATEGORY, httpTypes.PUT, body);
};
