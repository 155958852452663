import axios from "axios";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BASE_URL = "https://www.etniapp.com/CoreBack/api";

export const request = (url, method, data = null, params = null) => {
  const config = {
    method,
    url: BASE_URL + url,
    data,
    params,
  };
  return axios(config);
};
