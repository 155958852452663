import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editCategoryData,
  getCategoryList,
  saveCategoryData,
} from "app/services/categoryService";

const initialState = {
  error: null,
  categories: [],
  category: null,
  loadingCategory: false,
  categorySave: null,
};

export const getCategoriesList = createAsyncThunk("category/get", async () => {
  const id = window.localStorage.getItem("companyId");
  const res = await getCategoryList(id);
  return res.data.result;
});

export const saveCategory = createAsyncThunk("category/post", async (body) => {
  const res = await saveCategoryData(body);
  return res.data.result;
});

export const editCategory = createAsyncThunk("category/put", async (body) => {
  const res = await editCategoryData(body);
  return res.data.result;
});

const categorySlice = createSlice({
  initialState,
  name: "categories",
  reducers: {
    setCategory(state, actions) {
      const { payload } = actions;
      state.category = payload;
    },
    resetCategory(state, actions) {
      state.category = null;
    },
    resetCategorySave(state, actions) {
      state.categorySave = null;
    },
    resetCategories(state, actions) {
      state.categories = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoriesList.pending, (state) => {
        state.loadingCategory = true;
      })
      .addCase(getCategoriesList.fulfilled, (state, actions) => {
        state.loadingCategory = false;
        state.categories = actions.payload;
      })
      .addCase(getCategoriesList.rejected, (state, actions) => {
        state.loadingCategory = false;
        state.categories = [];
        state.error = actions.error.message;
      })
      .addCase(saveCategory.pending, (state) => {
        state.loadingCategory = true;
      })
      .addCase(saveCategory.fulfilled, (state, actions) => {
        state.loadingCategory = false;
        state.categorySave = actions.payload;
      })
      .addCase(saveCategory.rejected, (state, actions) => {
        state.loadingCategory = false;
        state.error = actions.error.message;
      })
      .addCase(editCategory.pending, (state) => {
        state.loadingCategory = true;
      })
      .addCase(editCategory.fulfilled, (state, actions) => {
        state.loadingCategory = false;
        state.categorySave = actions.payload;
      })
      .addCase(editCategory.rejected, (state, actions) => {
        state.loadingCategory = false;
        state.error = actions.error.message;
      });
  },
});
export const {
  setCategory,
  resetCategory,
  resetCategorySave,
  resetCategories,
} = categorySlice.actions;
export const categoryListReducer = categorySlice.reducer;
