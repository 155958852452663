export const topBarHeight = 64;
export const navbarHeight = 60;
export const sideNavWidth = 260;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const typesFormComponents = {
  text: "text",
  select: "select",
  button: "button",
  radio: "radio",
  checkbox: "checkbox",
  switch: "switch",
  normalButton: "normalButton",
  date: "date",
};

export const crudTypes = {
  create: "create",
  read: "detail",
  update: "edit",
  delete: "delete",
};

export const typePosition = {
  end: "end",
};

export const typesVariants = {
  outlined: "outlined",
  contained: "contained",
};

export const typesButtons = {
  loading: "loading",
  normal: "normal",
};

export const httpTypes = {
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  GET: "GET",
};

export const typesMessage = {
  error: "error",
  default: "default",
  success: "success",
  warning: "warning",
  info: "info",
};

export const regexValidation = {
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
  numnber: /^[0-9]+$/,
  hour: /^(?:[01]\d|2[0-3]):[0-5]\d$/,
};

export const statusOrder = {
  InEditing: "En edición",
  Processed: "En proceso",
  Canceled: "Cancelada",
  InShipment: "En proceso de envio",
  Delivered: "Entregado",
  Closed: "Cerrada",
};
