import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  editItemData,
  getItemByCompanyList,
  getItemList,
  getItemsByStore,
  saveItemData,
} from "app/services/itemService";
import { crudTypes } from "app/utils/constant";

const initialState = {
  errorItem: null,
  items: [],
  loadingItem: false,
  item: null,
  saveItem: null,
  modeItem: crudTypes.create,
};

export const getItems = createAsyncThunk("items/get", async () => {
  const id = window.localStorage.getItem("companyId");
  const res = await getItemByCompanyList(id);
  return res.data.result;
});


export const getItemsByStoreList = createAsyncThunk(
  "itemsByStore/get",
  async (id) => {
    const res = await getItemsByStore(id);
    return res.data.result;
  }
);

export const saveItemInfo = createAsyncThunk("items/post", async (body) => {
  const res = await saveItemData(body);
  return res.data.result;
});

export const editItemInfo = createAsyncThunk("items/put", async (body) => {
  const res = await editItemData(body);
  return res.data.result;
});

const itemSlice = createSlice({
  initialState,
  name: "items",
  reducers: {
    setItem(state, actions) {
      const { payload } = actions;
      state.item = payload;
    },
    resetItem(state, actions) {
      state.item = null;
    },
    resetItemSave(state, actions) {
      state.saveItem = null;
    },
    resetErrorItem(state, actions) {
      state.errorItem = null;
    },
    setModeItem(state, actions) {
      const { payload } = actions;
      state.modeItem = payload;
    },
    resetItems(state, actions) {
      state.items = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItems.pending, (state) => {
        state.loadingItem = true;
      })
      .addCase(getItems.fulfilled, (state, actions) => {
        state.loadingItem = false;
        state.items = actions.payload;
      })
      .addCase(getItems.rejected, (state, actions) => {
        state.loadingItem = false;
        state.items = [];
        state.errorItem = actions.error.message;
      })
      .addCase(getItemsByStoreList.pending, (state) => {
        state.loadingItem = true;
      })
      .addCase(getItemsByStoreList.fulfilled, (state, actions) => {
        state.loadingItem = false;
        state.items = actions.payload;
      })
      .addCase(getItemsByStoreList.rejected, (state, actions) => {
        state.loadingItem = false;
        state.items = [];
        state.errorItem = actions.error.message;
      })
      .addCase(saveItemInfo.pending, (state) => {
        state.loadingItem = true;
      })
      .addCase(saveItemInfo.fulfilled, (state, actions) => {
        state.loadingItem = false;
        state.saveItem = actions.payload;
      })
      .addCase(saveItemInfo.rejected, (state, actions) => {
        state.loadingItem = false;
        state.errorItem = actions.error.message;
      })
      .addCase(editItemInfo.pending, (state) => {
        state.loadingItem = true;
      })
      .addCase(editItemInfo.fulfilled, (state, actions) => {
        state.loadingItem = false;
        state.saveItem = actions.payload;
      })
      .addCase(editItemInfo.rejected, (state, actions) => {
        state.loadingItem = false;
        state.errorItem = actions.error.message;
      });
  },
});
export const {
  setItem,
  resetItem,
  resetItemSave,
  resetErrorItem,
  setModeItem,
  resetItems,
} = itemSlice.actions;
export const itemReducer = itemSlice.reducer;
